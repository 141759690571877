import {createApp} from 'vue'
import {createPinia} from 'pinia'

import 'whatwg-fetch'
import * as Sentry from '@sentry/vue'

import 'core-js/stable'
import smoothscroll from 'smoothscroll-polyfill'
import KProgress from 'k-progress-v3'

import App from './App.vue'
import router from './router'
import './styles/styles.scss'

// Todo: wenn auf nuxt umgestellt wurde, in nuxt.config verschieben (siehe https://github.com/webcareLBJ/storybook)
import '../node_modules/@webcarelbj/shared-components/dist/style.css'

import {
  BaseDate,
  BaseButton,
  BaseInput,
  BaseCheckbox,
  BaseRadio,
  BaseSelect,
  BaseAutocomplete,
  BaseClickBox,
  BaseTextArea,
  OptPhoneInput,
  InfoTooltip,
  TerminationHint,
  HintBox,
  TerminationReasons
} from 'shared-components'

import SubmitChange from '@/components/SubmitChange.vue'
import PatientTeaser from '@/components/dashboard/teaser/PatientTeaser.vue'
import CarePersonTeaser from '@/components/dashboard/teaser/CarePersonTeaser.vue'
import OutpatientServiceTeaser from '@/components/dashboard/teaser/OutpatientServiceTeaser.vue'
import ChangeContact from '@/components/ChangeContact.vue'
import GloveSizeForm from '@/components/dashboard/box/GloveSizeForm.vue'
import DeliveryIntervalForm from '@/components/dashboard/box/DeliveryIntervalForm.vue'
import TeaserHeader from '@/components/dashboard/teaser/TeaserHeader.vue'
import CasesHint from '@/components/dashboard/CasesHint.vue'
import GloveOptions from '@/components/product-selection/GloveOptions.vue'
import BackButton from '@/components/BackButton.vue'
import BaseChangeForm from '@/components/change/BaseChangeForm.vue'
import ProductsDashboard from '@/components/dashboard/box/Products.vue'
import ProductsProductSelection from '@/components/product-selection/Products.vue'
import BoxOptions from '@/components/dashboard/box/BoxOptions.vue'
import DeliveryAddressTeaser from '@/components/dashboard/teaser/DeliveryAddressTeaser.vue'
import DeliveryStop from '@/components/dashboard/DeliveryStop.vue'
import Pg51Teaser from '@/components/dashboard/teaser/Pg51Teaser.vue'
import DeliveryAddressForm from '@/components/change/DeliveryAddressForm.vue'
import DeliveryStopForm from '@/components/change/DeliveryStopForm.vue'
import LoginForm from '@/components/LoginForm.vue'
import Pg51Form from '@/components/change/Pg51Form.vue'
import Progressbar from '@/components/product-selection/Progressbar.vue'
import Header from '@/components/Header.vue'
import NewsTicker from '@/components/NewsTicker.vue'
import Footer from '@/components/Footer.vue'
import Boxes from '@/components/product-selection/Boxes.vue'
import EligibleForAidSelection from '@/components/EligibleForAidSelection.vue'
import ToggleSelection from '@/components/ToggleSelection.vue'
import HealthInsurance from '@/components/HealthInsurance.vue'
import ApiService from './services/ApiService'

const apiService = new ApiService()
const app = createApp(App)
const store = createPinia()

if (import.meta.env.VITE_APP_SENTRY_TEST) {
  Sentry.init({
    app,
    environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    tracesSampleRate: 0,
  })
}

app.provide('apiService', apiService)
app.provide('window', window)

// TODO: Remove/Refactor when switching to new storybook components
// track is kept to avoid vue warnings from currently used shared-components (need to inject track)
app.provide('track', {})

store.use(() => ({apiService}))
smoothscroll.polyfill()

store.use(() => ({apiService}))
smoothscroll.polyfill()

app.component('EligibleForAidSelection', EligibleForAidSelection)
app.component('ToggleSelection', ToggleSelection)
app.component('HealthInsurance', HealthInsurance)
app.component('OptPhoneInput', OptPhoneInput)
app.component('BaseInput', BaseInput)
app.component('BaseDate', BaseDate)
app.component('BaseButton', BaseButton)
app.component('BaseCheckbox', BaseCheckbox)
app.component('BaseRadio', BaseRadio)
app.component('BaseSelect', BaseSelect)
app.component('BaseAutocomplete', BaseAutocomplete)
app.component('BaseClickBox', BaseClickBox)
app.component('BaseTextArea', BaseTextArea)
app.component('KProgress', KProgress)
app.component('SubmitChange', SubmitChange)
app.component('PatientTeaser', PatientTeaser)
app.component('CarePersonTeaser', CarePersonTeaser)
app.component('OutpatientServiceTeaser', OutpatientServiceTeaser)
app.component('ChangeContact', ChangeContact)
app.component('GloveSizeForm', GloveSizeForm)
app.component('DeliveryIntervalForm', DeliveryIntervalForm)
app.component('InfoTooltip', InfoTooltip)
app.component('TeaserHeader', TeaserHeader)
app.component('CasesHint', CasesHint)
app.component('GloveOptions', GloveOptions)
app.component('BackButton', BackButton)
app.component('BaseChangeForm', BaseChangeForm)
app.component('ProductsDashboard', ProductsDashboard)
app.component('BoxOptions', BoxOptions)
app.component('DeliveryAddressTeaser', DeliveryAddressTeaser)
app.component('DeliveryStop', DeliveryStop)
app.component('Pg51Teaser', Pg51Teaser)
app.component('DeliveryAddressForm', DeliveryAddressForm)
app.component('DeliveryStopForm', DeliveryStopForm)
app.component('TerminationHint', TerminationHint)
app.component('HintBox', HintBox)
app.component('LoginForm', LoginForm)
app.component('Pg51Form', Pg51Form)
app.component('Progressbar', Progressbar)
app.component('MyHeader', Header)
app.component('NewsTicker', NewsTicker)
app.component('MyFooter', Footer)
app.component('Boxes', Boxes)
app.component('ProductsProductSelection', ProductsProductSelection)
app.component('TerminationReasons', TerminationReasons)

app.use(store)
app.use(router)
app.mount('#app')
