<template>
  <div class="login-form">
    <component
      v-bind="{ ...data }"
      :is="data.component"
      v-for="(data, key) in formData"
      :key="key"
      v-model="data.value"
      :name="key"
      :show-error-tooltip="loginFailed"
      :hide-success-icon="true"
      @change="onChange(data)"
      @keyup.enter="login()"
    ></component>
    <BaseButton
      label="Einloggen"
      :loading="isLoading"
      :disabled="isDisabled()"
      @click="login()"
    ></BaseButton>
  </div>
</template>

<script setup>
import formDataJson from '../schemas/loginDataSchema.json'
import {setErrors} from '@/mixins/formMixin'
import {inject, onUnmounted, reactive, ref} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {useStore} from '@/store/index'

const store = useStore()
const router = useRouter()
const route = useRoute()
const formData = reactive(formDataJson)
const apiService = inject('apiService')

const loginFailed = ref(false)
const isLoading = ref(false)

formData.birthdate.value = route.query.bd
formData.customerNumber.value = route.query.acc

onUnmounted(() => {
  formData.birthdate.value = ''
  formData.customerNumber.value = ''
})

function isDisabled() {
  return !formData.birthdate.value
}

async function login() {
  if (isDisabled()) {
    return
  }

  isLoading.value = true
  const response = await apiService.login({
    customerNumber: formData.customerNumber.value,
    birthdate: formData.birthdate.value
  })

  if (response.token) {
    await store.initialize(response.patient)
    await router.push('/dashboard')
  } else if (response.errors) {
    response.errors.reason
      ? (store.loginError = response.errors.reason)
      : setErrors(response.errors, formData)
    loginFailed.value = true
  }

  isLoading.value = false
}

function onChange(data) {
  data.hasError = false
  loginFailed.value = false
}
</script>
