<template>
  <div
    :class="[
      'header',
      {
        'header--sticky': isSticky,
        'header--with-news-ticker': store.isNewsTickerVisible
      }
    ]"
  >
    <div class="header__logo">
      <img
        src="@/assets/curabox-logo.png"
        srcset="@/assets/curabox-logo.png 1x, @/assets/curabox-logo.png 2x"
        alt="curabox-logo"
      />
    </div>
    <div class="header__buttons">
      <div v-show="false" class="header__buttons-contact">
        <img src="@/assets/icon-faq.svg" alt="Kontakt-Button" />
      </div>
      <div class="header__buttons-logout">
        <img
          v-if="store.patient"
          src="@/assets/icon-logout.svg"
          alt="Logout-Button"
          @click="logout"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/store/index'
import { useRouter } from 'vue-router'
import { onUnmounted, inject, ref } from 'vue'

const router = useRouter()
const store = useStore()

const apiService = inject('apiService')

const isSticky = ref(false)

window.addEventListener('scroll', onScroll)

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})

function onScroll() {
  isSticky.value = window.scrollY > 0 || window.pageYOffset > 0
}

function logout() {
  apiService.logout()
  store.patient = null
  router.push('/login')
}
</script>
