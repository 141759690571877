<template>
  <footer>
    <div class="footer">
      <ul class="footer__links">
        <li>
          <a target="_blank" href="https://www.pflege.de/impressum/">Impressum</a>
        </li>
        <li>
          <a target="_blank" href="https://www.pflege.de/kontakt/">Kontakt</a>
        </li>
        <li>
          <a target="_blank" href="https://www.pflege.de/datenschutz/">Datenschutz</a>
        </li>
        <li>
          <a @click="showLayer">AGB</a>
        </li>
        <li>
          <a href="https://www.pflege.de/hwg-pflichttexte" target="_blank" rel="nofollow">
            Pflichtangaben (HWG und Biozide)
          </a>
        </li>
        <li>
          <a target="_blank" href="https://service.pflege.de/curabox/faq">Fragen und Antworten</a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script setup>
import { useStore } from '@/store/index'

const store = useStore()

function showLayer() {
  store.showLayer = true
}
</script>
