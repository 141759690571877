<template>
  <div
    id="curabox-my"
    :class="['background-wrapper', { 'visible-news-ticker': store.isNewsTickerVisible }]"
  >
    <AutoLogout v-if="store.patient" />
    <NewsTicker page="meine.curabox.de" />
    <MyHeader />
    <RouterView />
    <MyFooter />
  </div>
</template>

<script setup>
import { inject, onMounted, onBeforeUnmount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import ScrollHelper from '@/helper/ScrollHelper'
import AutoLogout from '@/components/AutoLogout.vue'

const currentRoute = useRoute()
const router = useRouter()
const store = useStore()

const apiService = inject('apiService')

const refreshLoginInterval = ref(null)

onMounted(async () => {
  initTrustpilot()
  window.onpopstate = () => {
    ScrollHelper.scrollToTop()
  }
  window.addEventListener('resize', onResize)
  onResize()

  await router.isReady()
  if (currentRoute.query.acc && currentRoute.query.bd) {
    await loginFromCuraboxChange()
  } else {
    await login()
  }

  refreshLoginInterval.value = setInterval(
    () => {
      refreshLogin()
    },
    14 * 60 * 1000
  )
})

onBeforeUnmount(() => {
  clearInterval(refreshLoginInterval.value)
})

function initTrustpilot() {
  const trustpilot = document.createElement('script')
  trustpilot.setAttribute('src', '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js')
  document.head.appendChild(trustpilot)
}

function onResize() {
  store.isMobile = window.matchMedia('(max-width: 640px)').matches
  store.isTablet = window.matchMedia('(max-width: 768px)').matches
}

async function login() {
  let route = '/login'
  if (await isUserTokenValid()) {
    const patient = await apiService.getLoggedInPatient()
    await store.initialize(patient)
    if (store.patient) {
      route = currentRoute.name && currentRoute.name !== 'login' ? currentRoute.path : '/dashboard'
    } else {
      localStorage.removeItem('my-user-token')
    }
  }
  await router.push(route).catch(() => {})
}

async function refreshLogin() {
  if ((await isUserTokenValid()) && store.patient) {
    apiService.login({
      customerNumber: store.patient.data.customer_number,
      birthdate: store.patient.data.birthdate
    })
  }
}

async function loginFromCuraboxChange() {
  localStorage.removeItem('my-user-token')
  store.patient = null
  setCaseRequester()
  await router.push('/login')
}

function setCaseRequester() {
  store.caseRequester.isCarePerson = currentRoute.query.ctype === '1'
  store.caseRequester.firstname = currentRoute.query.cpfn
  store.caseRequester.lastname = currentRoute.query.cpln
  store.caseRequester.email = currentRoute.query.email
}

async function isUserTokenValid() {
  let isValid = false
  if (localStorage.getItem('my-user-token')) {
    const response = await apiService.get('api/validate-token')
    isValid = response.data === 'valid'
    if (!isValid) {
      localStorage.removeItem('my-user-token')
    }
  }
  return isValid
}
</script>
