<template>
  <div class="login">
    <TerminationHint v-if="store.loginError" :status="store.loginError" />
    <div v-else class="login-wrapper">
      <div class="login-headline">
        <span class="login-headline__title">Zugang zu Ihrer curabox</span>
        <span class="login-headline__subtitle">
          Inhalt, Lieferintervall und vieles mehr Ihrer curabox anpassen - einfach und komfortabel.
        </span>
      </div>
      <LoginForm />
      <div class="login-link">
        <span class="login-link__text">noch kein curabox Kunde?</span>
        <a class="link" href="https://www.curabox.de/beantragen">
          Jetzt kostenfrei Pflegemittel beantragen
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/store/index'

const store = useStore()
</script>
